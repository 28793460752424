<template>
  <div>
    <v-container>
      <v-row>
        <v-col>
          <div class="heading">
            Other Student Pages
            <div class="hLine"></div>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <v-container>
      <v-row justify="center">
        <v-col cols="12" sm="10">
          <v-row dense>
            <v-col
              v-for="(item, i) in studentList"
              :key="i"
              cols="12"
              sm="6"
              md="4"
              lg="3"
            >
              <v-card
                class="student-card"
                @click="selectStudent(item.id)"
                elevation="3"
              >
                <v-card-text>
                  <div class="d-flex align-center">
                    <v-avatar size="70" class="mr-3">
                      <img
                        :src="item.profile_pic || require('@/assets/publicDonationPage/student.png')"
                        alt="student"
                      />
                    </v-avatar>
                    <div>
                      <div class="student-name">
                        {{ item.student_first_name }} {{ item.student_last_name }}
                      </div>
                      <div class="student-grade">
                        {{ item.grade_teacher }}
                      </div>
                      <div class="student-grade-detail">
                        (Grade {{ item.grade_title }})
                      </div>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12" sm="6" md="4" lg="3">
              <v-card class="create-card" outlined @click="toggleCreateStudentModal({ show: true, actionType: 'add' })">
                <v-card-text class="text-center">
                  <v-icon color="primary" size="50">mdi-plus-box</v-icon>
                  <div class="mt-3 create-text">Create Student Page</div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  API_PDP_STUDENT_LIST,
  API_ADMIN_GET_SCHOOL_DETAIL,
} from "@/constants/APIUrls";
import Axios from "@/api/BaseAxios";
import { ID } from "@/constants/APIKeys";
import { STUDENT } from "@/constants/ModuleKeys";
import { ROUTER_URL } from "@/constants/urls";
export default {
  name: "StudentCards",
  data() {
    return {
      studentList: [],
      schoolDetail: {},
      currentStudent: null,
    };
  },
  created() {
    this.$root.$refs.studentCard = this;
  },
  computed: {
    ...mapGetters({
      getCampaign: "publicDonationPage/getCampaign",
      userDetails: "publicDonationPage/getUserDetails",
    }),
    getCampaignDetail() {
      return this.getCampaign;
    },
    schoolFundRaisedPercentage() {
      return Math.round(
        (this.schoolDetail.total_fund_raised / this.schoolDetail.school_goal) *
          100
      );
    },
  },
  watch: {},
  methods: {
    ...mapActions({
      toggleCreateStudentModal: "publicDonationPage/toggleCreateStudentModal",
      updateSelectedStudent: "publicDonationPage/updateSelectedStudent",
    }),
    selectStudent(id) {
      let student = this.studentList.find((student) => student.id === id);
      this.updateSelectedStudent({ student });
      this.$router.push({
        name: ROUTER_URL.publicParentDashboard.name,
        query: { ...this.$route.query, [STUDENT]: student[ID] },
      });
      this.getStudentListData();
    },
    filterStudent(list) {
      // console.log(list);
      this.studentList = list;
      console.log(this.studentList);
      let index = this.studentList.findIndex(
        (item) => item.id == this.currentStudent
      );
      this.studentList.splice(index, 1);
    },
    getStudentListData() {
      const _this = this;
      _this.loading = true;
      const successHandler = (res) => {
        // _this.studentList = res.data.student_list;
        this.filterStudent(res.data.student_list);
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      const finallyHandler = () => {
        this.getSchoolDetail();
      };
      let formData = {};
      return Axios.request_GET(
        API_PDP_STUDENT_LIST,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true,
        finallyHandler
      );
    },
    getSchoolDetail() {
      const successHandler = (res) => {
        this.schoolDetail = res.data.school_detail;
      };
      const failureHandler = (res) => {
        console.log(res);
      };
      let formData = {};
      formData["school_id"] = this.getCampaignDetail.student_detail.school_id;
      formData["campaign_id"] = this.getCampaignDetail.campaign_detail.id;
      Axios.request_GET(
        API_ADMIN_GET_SCHOOL_DETAIL,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        false
      );
    },
  },
  mounted() {
    this.currentStudent = this.$route.query.student;
    this.getStudentListData();
  },
};
</script>
<style scoped>
.heading {
  font-size: 26px;
  font-family: 'Roboto Slab', serif;
  font-weight: 600;
  color: #2c1963;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  place-content: center;
}

.hLine {
  background-color: #2c1963;
  width: 50px;
  height: 4px;
  border-radius: 20px;
  margin-left: 15px;
}

.student-card {
  border-radius: 14px;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  cursor: pointer;
}

.student-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
}

.student-name {
  font-size: 18px;
  font-weight: 700;
  color: #2c1963;
}

.student-grade {
  font-size: 14px;
  color: #555;
}

.student-grade-detail {
  font-size: 13px;
  color: #777;
}

.create-card {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 2px dashed #2c1963;
  border-radius: 14px;
  background-color: rgba(237, 237, 237, 0.2);
  cursor: pointer;
  transition: 0.3s ease;
}

.create-card:hover {
  background-color: rgba(237, 237, 237, 0.3);
}

.create-text {
  font-size: 18px;
  font-weight: 600;
  color: #2c1963;
}

@media only screen and (max-width: 600px) {
  .heading {
    font-size: 20px;
  }
  .hLine {
    width: 30px;
    height: 3px;
    margin-left: 10px;
  }
}

</style>
